<template>
  <div>
    <div class="notification">
      <b-field label="Kursdatum">
        <b-datepicker
          placeholder="Datum"
          icon="calendar-today"
          locale="de-DE"
          v-model="course_enquiry.date"
          :disabled="is_loading"
          editable
        >
        </b-datepicker>
      </b-field>
      <b-field label="Kursdauer">
        <b-radio-button
          v-model="course.hours"
          :native-value="2"
          type="is-primary is-light is-outlined"
          :disabled="is_duration_enabled(2)"
        >
          <span>bis 2 Stunden</span>
        </b-radio-button>

        <b-radio-button
          v-model="course.hours"
          :native-value="4"
          type="is-primary is-light is-outlined"
          :disabled="is_duration_enabled(4)"
        >
          <span>bis 4 Stunden</span>
        </b-radio-button>

        <b-radio-button
          v-model="course.hours"
          :native-value="8"
          type="is-primary is-light is-outlined"
          :disabled="is_duration_enabled(8)"
        >
          <span>bis 8 Stunden</span>
        </b-radio-button>
      </b-field>
      <b-field :label="`Anzahl Teilnehmer (${course_enquiry.participants})`">
        <b-slider
          v-model="course_enquiry.participants"
          :disabled="is_loading"
          :custom-formatter="(val) => val + ' Teilnehmer'"
          :min="5"
          :max="100"
          lazy
        ></b-slider>
      </b-field>
      <b-field
        label="Distanz von Zieladresse und Lageradresse Hefenhofen TG in Km"
      >
        <b-numberinput
          v-model="course_enquiry.distance"
          placeholder="99"
          :min="0"
          :max="250"
          step="1"
          type="is-outlined"
          controls-position="compact"
          :disabled="is_loading"
        ></b-numberinput>
      </b-field>

      <b-field>
        <p>
          <b>Lageradresse:</b><br />
          Swiss Domino Effect, Tohuebstrasse 2, 8580 Hefenhofen, Schweiz<br />
          <a href="https://goo.gl/maps/bohkr18UhB1TLtAt5" target="_blank"
            >In Google Maps öffnen</a
          >
        </p>
      </b-field>

      <div class="notification is-warning" v-if="error_validation">
        {{ error_validation }}
      </div>

      <div class="buttons">
        <b-button
          @click="requestCourse"
          :class="{ 'is-loading': is_loading }"
          :disabled="!changed"
          >Angebot berechnen</b-button
        >
      </div>
    </div>

    <div
      class="notification is-primary is-light"
      v-if="calculated_price"
      :class="{ hidden: is_loading }"
    >
      <h4 class="title is-4">Berechnetes Angebot</h4>
      <table
        class="table is-striped is-bordered is-fullwidth"
        v-if="calculated_price"
      >
        <tr>
          <th>Position</th>
          <th>Betrag</th>
        </tr>
        <tr v-for="(position, index) in calculated_price.price" :key="index">
          <td>{{ position.name }}</td>
          <td align="right">
            CHF {{ formatNumberToTwoDigits(position.value) }}
          </td>
        </tr>
        <tr>
          <th>Summe</th>
          <th align="right">
            CHF {{ formatNumberToTwoDigits(sumUpCalculatedPrice()) }}
          </th>
        </tr>
      </table>
      <div class="buttons">
        <b-button type="is-primary" :disabled="changed" @click="openSubmitForm"
          >Angebot anfragen</b-button
        >
      </div>
      <p>
        * Nach der Prüfung Ihrer Angaben erhalten Sie von uns eine Offerte mit
        dem Angebot. Nach Zusage Ihrerseits wird das Angebot für Sie gebucht.
      </p>
    </div>
    <FillInForm
      type="course"
      :shown="fillin_form_shown"
      :is_loading="is_loading_submit_form"
    />
  </div>
</template>
<style scoped>
.hidden {
  opacity: 0.4;
}
</style>
<script>
import { requestCourse, submitRent } from "@/webapi";
import FillInForm from "@/components/FillInForm";
export default {
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      course_enquiry: {
        date: null,
        participants: 5,
        distance: 0,
      },
      calculated_price: null,
      error: null,
      is_loading: false,
      changed: true,
      fillin_form_shown: false,
      is_loading_submit_form: false,
      error_validation: null,
    };
  },
  watch: {
    course_enquiry: {
      handler: function (oldObj, newObj) {
        this.detectChange();
      },
      deep: true,
    },
  },
  methods: {
    openSubmitForm() {
      this.fillin_form_shown = true;
    },
    handleSubmitForm(client, addition) {
      // set organisation type
      client.organization_type = "company";
      let course = {
        costs: {
          positions: this.calculated_price.price,
        },
        customer: client,
        info: {
          dates: {
            date_from: this.convertDate(this.course_enquiry.date),
            date_to: this.convertDate(this.course_enquiry.date),
          },
          description: addition.description,
          type: "workshop",
          domino_count: this.course_enquiry.participants * 1000,
          participants: this.course_enquiry.participants,
        },
      };
      this.is_loading_submit_form = true;
      submitRent(course)
        .then((subResp) => {
          this.is_loading_submit_form = false;
          if (subResp.data.state) {
            this.$router.push({
              name: "status",
              params: {
                type: "courseSubmitted",
              },
            });
          } else {
            this.error =
              "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.";
          }
        })
        .catch((error) => {
          this.is_loading_submit_form = false;
          this.error = `Es ist ein Fehler aufgetreten. Überprüfen Sie Ihre Internetverbindung. (${error})`;
        });
    },
    closeSubmitForm() {
      this.fillin_form_shown = false;
    },
    requestCourse() {
      if (!this.validateCourse()) {
        return;
      }
      this.is_loading = true;
      this.error = null;
      this.course_enquiry.hours = this.course.hours;
      requestCourse({ course_enquiry: this.course_enquiry })
        .then((resp) => {
          if (resp.data.state) {
            this.calculated_price = resp.data.data;
            this.changed = false;
          } else {
            this.error = "Es ist ein Fehler aufgetreten.";
          }
          this.is_loading = false;
        })
        .catch((error) => {
          this.is_loading_submit_form = false;
          this.error = `Es ist ein Fehler aufgetreten. Überprüfen Sie Ihre Internetverbindung. (${error})`;
        });
    },
    validateCourse() {
      this.error_validation = null;
      if (
        !this.course_enquiry.date ||
        new Date(this.course_enquiry.date) < new Date()
      ) {
        this.error_validation = "Bitte geben Sie ein gültiges Kursdatum an.";
        return false;
      }
      if (this.course_enquiry.distance <= 0) {
        this.error_validation =
          "Bitte geben Sie eine gültige Distanz in km ein.";
        return false;
      }
      if (this.course_enquiry.participants <= 0) {
        this.error_validation =
          "Bitte geben Sie eine gültige Anzahl Teilnehmer an. (Mindestens 5 und maximal 100)";
        return false;
      }
      return true;
    },
    sumUpCalculatedPrice() {
      if (this.calculated_price) {
        return this.calculated_price.price.reduce(
          (partialSum, a) => partialSum + Number(a.value),
          0
        );
      }
      return 0;
    },
    formatNumberToTwoDigits(number) {
      return Number(number).toFixed(2);
    },
    is_duration_enabled(hours) {
      return this.course.hours != hours;
    },
    detectChange() {
      this.changed = true;
    },
    convertDate(rawDate) {
      if (rawDate.toString().includes("T")) {
        let date = new Date(rawDate);
        let dateCorrected = new Date(
          date.getTime() - date.getTimezoneOffset() * 60000
        );
        return dateCorrected.toISOString().split("T")[0];
      }
      return rawDate;
    },
  },
  components: {
    FillInForm,
  },
};
</script>