import { render, staticRenderFns } from "./CourseConfigurator.vue?vue&type=template&id=567378d4&scoped=true&"
import script from "./CourseConfigurator.vue?vue&type=script&lang=js&"
export * from "./CourseConfigurator.vue?vue&type=script&lang=js&"
import style0 from "./CourseConfigurator.vue?vue&type=style&index=0&id=567378d4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "567378d4",
  null
  
)

export default component.exports